import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Template from "@/views/Template";
import ProductPresentation from "@/components/ProductPresentation";
import ProductAdvantages from "@/components/ProductAdvantages";
import AboutProduct from "@/components/AboutProduct";
import Typography from "@/components/Typography";
import InvestTime from "@/containers/InvestTime";
import PlansBox from "@/components/PlansBox";
import Button from "@/components/Button/Button";
export const _frontmatter = {
  "title": "Check UP",
  "path": "/check-up",
  "date": "2019-01-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Template;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ProductPresentation contactLink="https://wa.me/5581994019577" knowMoreLink="https://www.instagram.com/leancontabilidade" description="Nós realizamos um check up das pendências e montamos um plano de ação para manter sua empresa sempre em dia com as obrigações fiscais." title={() => <>
      Situação cadastral{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        inapta
      </Typography>{" "}
      do{" "}
      <Typography as="span" weight={700} variant="h3" mdxType="Typography">
        CNPJ
      </Typography>{" "}
      na Receita Federal? Entre em contato.
    </>} mdxType="ProductPresentation" />
    <InvestTime mdxType="InvestTime">
  <Typography align="center" color="primary" variant="h1" mdxType="Typography">
    Invista mais tempo no seu{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      negócio
    </Typography>{" "}
    e deixe a burocracia com a gente. Faça já sua{" "}
    <Typography as="span" color="primary" variant="h1" weight={300} mdxType="Typography">
      contabilidade online.
    </Typography>
  </Typography>
    </InvestTime>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      